
:root {
    --transp : 0.7;
}

.light-box {
    display: flex;
    visibility: hidden;
    opacity: 0;

    position: fixed;
    top: 0px;
    left:0px;
    width: 100%;
    height: 100vh;

    justify-content: center;
    align-items: center;

    z-index: 10;

    /* ff 3.6+ */
    background:-moz-radial-gradient(circle at 16% 30%, rgba(255, 85, 0, var(--transp)) 0%, rgba(74, 113, 225, var(--transp)) 100%);

    /* safari 5.1+,chrome 10+ */
    background:-webkit-radial-gradient(circle at 16% 30%, rgba(255, 85, 0, var(--transp)) 0%, rgba(74, 113, 225, var(--transp)) 100%);

    /* opera 11.10+ */
    background:-o-radial-gradient(circle at 16% 30%, rgba(255, 85, 0, var(--transp)) 0%, rgba(74, 113, 225, var(--transp)) 100%);

    /* ie 10+ */
    background:-ms-radial-gradient(circle at 16% 30%, rgba(255, 85, 0, var(--transp)) 0%, rgba(74, 113, 225, var(--transp)) 100%);

    /* global 92%+ browsers support */
    background:radial-gradient(circle at 16% 30%, rgba(255, 85, 0, var(--transp)) 0%, rgba(74, 113, 225, var(--transp)) 100%);

    transition: 0.75s all ease;
}

.contact__container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: space-between;
    opacity: 1;

    position: relative;
    max-width: 80%;
    /* padding: 20px 0px; */
    margin: 0 auto;
    
    /* border: 5px solid brown; */
    background: white;

    color:black;

    font-weight: 600;
    border-radius: 10px;

    transition: 200ms all ease-out;
}

.light-box .wave-line__container{
    margin: 20px 0;
}

.light-box .wave-line {
    margin: 0px;
}

.light-box__contents {
    /* display: block; */
    /* padding: 20px 60px; */
    margin: 0 60px;
}

.contact__container ul {
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items:center;
    margin: 0 auto;
    padding: 0px;
    /* padding:20px */
}

.contact__container li {
    display: block;
    padding: 5px;
    margin: 0 auto;
}
.contact__container a {
    color:inherit;
    text-decoration: none;
    margin: 0 auto;
}
.contact__container img {
    margin:0 auto;
    flex:1;
    max-width: 90%;
    margin-top: 5px;

    /* transform: scale(0.85); */
    filter: drop-shadow();
    transition: all 0.2s ease;
    filter: drop-shadow(0 0 0.2rem rgba(0, 0, 0, 0.342));

}

.contact__container img:hover {
    transform:scale(0.9);
    filter: none ;
}

.contact__container span {
    position: absolute;
    opacity: 1;
    /* float:right; */
    top: 10px;
    right: 15px;
    margin: 0;
    padding: 0;

    z-index: 30;
    font-size: 1.5rem;
    cursor:pointer;
    transition: 0.2s all ease;
}

.contact__container span:hover {
    transform: scale(1.2);
}