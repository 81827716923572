 
.artpiece__card {
    position: relative;

    overflow: hidden;
    justify-content: center;
    align-items: center;
 
    border-radius: 10px;
    
    transition: all 0.2s ease;
}

.landscape__card{
    grid-row-end: span 1;
}
.portrait__card{
    grid-row-end: span 2;
}

.artpiece__card:hover {
    transform: scale(1.05);
    cursor: pointer;
    box-shadow: 7px 15px 48px 20px rgba(0, 0, 0, 0.15);
}

.artpiece__card img, .card__delete img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.button {
    display: flex;
    position: absolute;
    bottom:0.2rem;
    right:0.2rem;
    text-align: center;
    justify-content: center;
    align-items: center;

    font-size: 0.7rem;
    padding:2px 3px;
    border-radius: 10px;

    transition:all 0.2s ease;
    /* background-color: white; */
    /* width: 20%; */
    /* height:20%; */
    /* font-size:  */
}

.card__delete span {
    font-size: 1rem;
    font-weight: bolder;
    color:red;  
}

.button :hover {
    font-size: 1.1rem;
    
    /* transform: scale(1,05); */
}

@media screen and (max-width:450px) {
    .artpiece__card .button {
        padding:5px 10px;
        font-size: 0.8rem;
    }
}