

.artshow__container {
    /* display : flex; */
    position: relative;
    /* flex-direction: column; */
    /* justify-content: center; */
    /* padding : 0px; */
    max-width: 90%;
    /* justify-content: flex-start; */
    /* align-items: center; */
    transition: 0.2s all ease;
    margin: 0 auto;
    /* margin-left: auto; */
    /* margin-right: auto; */
    /* margin: 20px; */
}

/* .gallery {
    display: block;
    width: 100%;
    columns: 1 ;
    column-gap: 0.8rem;
    margin: 0 auto;
} */

/* .gallery {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    width: 100;
    margin: 0 auto;
} */

.gallery {
    display: grid;
    margin: 0 auto;

    max-width: 98vw;
    grid-gap: 20px;

    padding-bottom: 20px;

    grid-template-columns: repeat(auto-fill, 180px);
    grid-auto-rows: minmax(90px, auto);
    justify-content: center;	
}

.gallery__delete  {
    display: flex;
    position: relative;
    top: 0px;
    left: 0px;
    /* flex:0; */
    justify-content: flex-start; 
    align-items: flex-start;   /* grid-template-columns: repeat(7,100px); */
    flex-wrap: wrap;
}


@media screen and (max-width: 440px) {
    .gallery {
        grid-template-columns: 90%;
    } 

}

@media screen and (min-width: 770px) {
    /* .gallery {
        columns: 24vw 4;
    } */

    /* .gallery__delete{
        columns: 10 auto;
    } */


}
@media screen and (min-width: 990px) {
    /* .artshow__container{
        max-width: 98%;
    } */

    /* .gallery {
        columns: 15vw 8 ;
    } */

    /* .gallery__delete{
        columns: 20 5vw;
    } */

}
