
.carousel__container {
    display: flex;
    position: fixed;

    top:0;
    left:0;

    justify-content: center;
    align-items: center;

    background-color: rgba(0, 0, 0, 0.83);

    width: 100vw;
    height: 100vh;
}

.carousel__container .close {
    color: white;
    position: absolute;
    top: 4rem;
    right: 2rem;
    z-index: 20;
    z-index: 20;
    font-size: 2rem;
}


.carousel__container .close:hover {
    transform: scale(1.2);
    cursor: pointer;
}

.carousel__art {
    display: flex;
    /* position: relative;

    top:50%;
    transform: translateY(-50%);    */

    width: 80vw;
    height: 80vh;

    /* width: 200px; */

    justify-content: center;
    align-items: center;

    /* margin: 0 auto; */
    /* overflow: hidden; */
}

.image__container {
    display: flex;
    max-width:100%;
    height:100vh;
    justify-content: center;
    align-items: center;
}

.carousel__art img {
    display: block;
    /* position: relative; */

    width:100%;
    height:80%;

    /* top:50%;

    transform: translateY(-50%);    */

    /* max-width: 100%; */
    /* max-height: 100%; */
    /* max-width:100%; */

    object-fit: contain;
}