.gallery__container {
    position:absolute;
    /* display: flex; */
    /* align-items: center; */
    /* flex-direction: column; */
    top:0px;
    /* margin */
    min-width: 100%;
    min-height: 100vh;
    /* height:100vh; */
    background-image: url('../../images/layout/gallery1080p.jpeg');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    background-attachment: fixed;
    /* overflow: scroll; */

    color:white;
}

.gallery__title {
    display: flex;
    flex: 1;
    flex-direction: column;

    border-radius: 10px;
    background-color: rgba(255, 255, 255, 0.262);
    max-width: 80%;
    margin: 0 auto; 
    padding: 2%;     
    margin-top: 70px;  
    margin-bottom: 0px; 
    backdrop-filter: blur(2px);
    /* background-clip: blu; */
  
}

.gradient__text {
    background: var(--orange-blue-text);
    background-clip:text;
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
}

.wave-line__container {
    width:100%;
    position: relative;
    overflow: hidden;
    margin-bottom: 20px;
}

.wave-line {
    width: 120%;
    /* position: relative; */
    /* overflow: hidden; */
    /* width */
    height: 20px;
    background-image: url('../../images/pattern/waves.svg');
    background-size:contain ;
    margin-top:15px;
    /* transform: translateX(20px); */
}

.gallery__container .wave-line {
    filter: invert(100%) sepia(0%) saturate(0%) hue-rotate(355deg) brightness(104%) contrast(103%);
}

@media (prefers-reduced-motion: no-preference) {
    .wave-line {
        animation: waves infinite 5s ease-in-out;
        /* transform: translateX(100%); */
    }
}

@keyframes waves {
    0% {transform: translateX(-15px);}

    50% {transform: translateX(-5px);}

    100% {transform: translateX(-15px);}
}
